import React from 'react';   

import { NextSeo } from 'next-seo';  


export default function Meta({title, description, slug, noindex = false}) { 
  const defaultDesc = `${process.env["NEXT_PUBLIC_COMPANY_NAME"]} is a Community of Elopement Planners, Officiants and Elopement Photographers. LGBTQ Friendly. Environmentally Conscious. Leave No Trace Aware.`;  
  const metaUrl = slug !== '' ? `${process.env["NEXT_PUBLIC_HOST"]}/${slug}` : process.env["NEXT_PUBLIC_HOST"];  
  return (
    <NextSeo
      title={ title }
      description={ description }
      canonical={ metaUrl }
      openGraph={{
        url: metaUrl,
        title: title, 
        description: description !== '' ? description : defaultDesc,
        images: [
          {
            url: `${process.env["NEXT_PUBLIC_HOST"]}/img/meta/meta-og-image.png`,
            width: 1200,
            height: 630,
            alt: title,
          }
        ],
        site_name: process.env["NEXT_PUBLIC_COMPANY_NAME"],
      }}
      // twitter={{ 
      //   handle: '@elopifycom',
      //   site: '@elopifycom',
      //   cardType: 'summary_large_image', 
      // }}
      noindex={noindex}
    />
  ); 
}
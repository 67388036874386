/* eslint-disable jsx-a11y/anchor-has-content */

// https://github.com/mui-org/material-ui/tree/master/examples/nextjs

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
// import MuiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

interface IProps {
    as?: any;
    href?: any; 
    children?: any; 
    className?: any; 
    // any other props that come into the component
}

const NextComposed = React.forwardRef<HTMLInputElement, IProps>(function NextComposed(props:any, ref:any) {
  const { as, href, ...other } = props;

  return (
    <NextLink href={href} as={as}>
      <a ref={ref} {...other} />
    </NextLink>
  );
});

NextComposed.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}; 

function ButtonLink(props:any) {
  const {
    href,
    activeClassName = 'active',
    className: classNameProps,
    innerRef,
    naked,
    ...other
  } = props;

  const router = useRouter();
  const pathname = typeof href === 'string' ? href : "ddd";
  const className = clsx(classNameProps, {
    [activeClassName]: router ? router.pathname === pathname && activeClassName : "",
  });

  if (naked) {
    return <NextComposed className={className} ref={innerRef} href={href} {...other} />;
  }

  return (
    <Button component={NextComposed} className={className} ref={innerRef} href={href} {...other} />
  );
} 

export default React.forwardRef((props:any, ref:any) => <ButtonLink {...props} innerRef={ref} />); 